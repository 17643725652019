<template>
  <div class="card card-body shadow-sm infomations-list">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('restaurant.list_title')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.KYY_NEWS_CATEGORY_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-6 col-lg-6 col-sm-8">{{ filters.name.label }}</label>
              <div class="col-xl-6 col-lg-6 col-sm-12 gr-icheck mt-0">
                <app-input :name="filters.name.name" input-style="normal"
                           v-model="filters.name.value"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common_vn.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common_vn.detail") }}
          </button>
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  export default {
    name: "NewsCategoryListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          name: {
            name: "name",
            condition: "like",
            label: this.$t("news_category.name")
          },
        },
        columns: [
          {name: "name", label: this.$t("news_category.name"), sortable: true, textAlign: 'text-left'},
          {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        meta: [],
        endPoint: ''
      };
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.KYY_NEWS_CATEGORY_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.KYY_NEWS_CATEGORY_EDIT, params: {id: entry.id}})
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {}
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
    },
    mounted: function () {
      // this.$request.get(this.ENDPOINT.RESTAURANT_LIST).then(res => {
      //   this.meta = {
      //     data: res.data,
      //   }
      // })
    },
  }
</script>
